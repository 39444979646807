* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  background: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  padding: 0;
  margin: 0;
}
body h1, body h2, body h3, body h4, body p {
  margin: 0;
}
body a {
  text-decoration: none;
}

.wrapper {
  padding: 0 2rem;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.wrapper .wrapper__column {
  flex-basis: 50%;
  flex-shrink: 0;
}
.wrapper .wrapper__column:first-of-type {
  padding-right: 5rem;
}
.wrapper .wrapper__column:last-of-type {
  padding-left: 5rem;
}
.wrapper.line:after {
  content: "";
  width: 1px;
  height: calc(100% + 181px);
  background: #E6E6E6;
  position: absolute;
  left: calc(2rem + 17px);
  top: calc(-5rem - 81px);
  z-index: 0;
}

@media (max-width: 768px) {
  body h1 {
    font-size: 3rem;
  }

  .wrapper {
    padding: 0 2rem;
  }
  .wrapper .wrapper__column {
    flex-basis: 100%;
  }
  .wrapper .wrapper__column:first-of-type {
    padding-right: 0;
  }
  .wrapper .wrapper__column:last-of-type {
    margin-top: 3rem;
    padding-left: 0;
  }
  .wrapper.line:after {
    width: 0 !important;
  }

  .contact-form.register {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
.btn---desc {
  font-size: 1.2rem;
  font-style: italic;
  margin-top: 1rem;
  display: inline-block;
}

.btn, button {
  font-weight: 600;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  border: none;
  cursor: pointer;
  position: relative;
}
.btn:focus, button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.btn.btn--circle, button.btn--circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.btn.btn--circle.s, button.btn--circle.s {
  width: 20px;
  height: 20px;
}
.btn.btn--primary, button.btn--primary {
  background: #00A99D;
  color: #fff !important;
  transition: all 0.2s;
}
.btn.btn--primary:hover, button.btn--primary:hover {
  background: #F7931E;
}
.btn.btn--secondary, button.btn--secondary {
  background: #F7931E;
  color: #fff;
  transition: all 0.2s;
}
.btn.btn--secondary:hover, button.btn--secondary:hover {
  background: #f38709;
}
.btn.btn--white, button.btn--white {
  color: #4D4D4D;
  background: #fff;
  transition: all 0.2s;
}
.btn.btn--white:hover, button.btn--white:hover {
  background: #f7f7f7;
}
.btn.btn--success, button.btn--success {
  color: #fff;
  background: #10B981;
  transition: all 0.2s;
}
.btn.btn--success:hover, button.btn--success:hover {
  background: #0fab77;
}
.btn.btn--success-inv, button.btn--success-inv {
  background: #A7F3D0;
  color: #064E3B;
  transition: all 0.2s;
}
.btn.btn--success-inv:hover, button.btn--success-inv:hover {
  background: #9af1c9;
}
.btn.btn--danger, button.btn--danger {
  color: #fff;
  background: #EF4444;
  transition: all 0.2s;
}
.btn.btn--danger:hover, button.btn--danger:hover {
  background: #ee3636;
}
.btn.btn--danger-inv, button.btn--danger-inv {
  background: #FECACA;
  color: #7F1D1D;
  transition: all 0.2s;
}
.btn.btn--danger-inv:hover, button.btn--danger-inv:hover {
  background: #febbbb;
}
.btn.btn--shadow, button.btn--shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
.btn.btn--text, button.btn--text {
  background: transparent;
  color: #000;
}
.btn.btn--text:hover, button.btn--text:hover {
  text-decoration: underline;
}
.btn.btn--text.gray, button.btn--text.gray {
  color: #4D4D4D;
}
.btn.btn--text.primary, button.btn--text.primary {
  color: #00A99D;
}
.btn.btn--text.danger, button.btn--text.danger {
  color: #EF4444;
}
.btn.btn--text.p--0, button.btn--text.p--0 {
  padding: 0;
}
.btn.btn--text.xs, button.btn--text.xs {
  font-size: 1.2rem;
}
.btn.btn--l, button.btn--l {
  font-size: 2.2rem;
  padding: 1.6rem 4rem;
}
.btn.btn--s, button.btn--s {
  font-size: 1.6rem;
  padding: 1.4rem 3.6rem;
}
.btn.btn--xs, button.btn--xs {
  font-size: 1.3rem;
  padding: 0.8rem 1rem;
  text-align: center;
}
.btn.btn--xs.fixed, button.btn--xs.fixed {
  width: 130px;
}
.btn.btn--more, button.btn--more {
  height: 32px;
  width: 32px;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.btn--conferences, button.btn--conferences {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}
.btn.btn--conferences img, button.btn--conferences img {
  margin: 0 !important;
}

@media (max-width: 768px) {
  .btn.btn--l {
    font-size: 2rem;
    padding: 1rem 3rem;
  }
  .btn.btn--s {
    font-size: 1.6rem;
    padding: 1.2rem 3rem;
  }
}
section {
  padding: 6rem 0;
  position: relative;
}
section.conf {
  padding-top: calc(80px + 5rem);
  margin-top: -80px;
  padding-bottom: 5rem;
  position: relative;
}
section h2 {
  font-size: 3rem;
  margin-bottom: 5rem;
  position: relative;
  text-transform: uppercase;
}
section h2.text--center {
  margin-left: 0 !important;
}
section h2.dot {
  margin: 0 0 8rem 10rem;
}
section h2.dot.dot--bottom:after {
  content: "";
  width: 34px;
  height: 34px;
  border-radius: 50%;
  position: absolute;
  bottom: -5rem;
  left: calc(50% - 17px);
}
section h2.dot.dot--left:after {
  content: "";
  width: 34px;
  height: 34px;
  border-radius: 50%;
  position: absolute;
  left: -10rem;
  top: 0;
  z-index: 1;
}
section h2.dot.dot--primary:after {
  background: #00A99D;
}
section h2.dot.dot--secondary:after {
  background: #F7931E;
}
section h3 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 6rem;
  font-weight: 600;
}
section h3.heading--icon {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  text-transform: uppercase;
  margin-bottom: 5rem;
}
section h3.heading--icon span {
  font-size: 18px;
  text-transform: uppercase;
}
section h3.heading--icon img {
  height: 25px;
  width: 25px;
  margin-right: 1rem;
}
section .subheading {
  font-size: 1.6rem;
  margin: 0 0 7rem 10rem;
}
section .subheading.text--center {
  margin-left: 0 !important;
}
section.white {
  background: #fff;
  color: #000;
}
section.white.bb {
  border-bottom: 1px solid #E6E6E6;
}
section.light {
  background: #F4F4F4;
  color: #000;
}
section.bright {
  background: #E6E6E6;
  color: #000;
}
section.transparent {
  background: transparent;
  color: #000;
}
section.img {
  position: relative;
  color: #fff;
  z-index: 0;
}
section.img:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}
section.img .img--bg {
  z-index: -1;
}

@media (max-width: 768px) {
  section {
    padding: 6rem 0;
  }
  section.conf {
    padding: calc(80px + 4rem) 0;
  }
  section h2 {
    font-size: 2.4rem;
  }
  section h2.dot {
    margin-left: 6rem;
  }
  section h2.dot.dot--left:after {
    width: 24px;
    height: 24px;
    left: -6rem;
  }
  section h2.dot.dot--bottom:after {
    width: 24px;
    height: 24px;
    bottom: -3.2rem;
    left: calc(50% - 12px);
  }
  section .subheading {
    font-size: 1.4rem;
    margin-left: 6rem;
    margin-bottom: 6rem;
  }
}
.input__item.error {
  position: relative;
  border: 1px solid #EF4444 !important;
}
.input__item.error.content-check-container {
  border-radius: 8px;
}

.error .content-check-container .content-check {
  border: 1px solid #EF4444 !important;
}

.error-msg {
  color: red;
  font-size: 1rem;
  font-style: italic;
}

.input-column {
  display: flex;
}

.input-container {
  width: 100%;
  margin-bottom: 1.6rem;
  z-index: 1;
  position: relative;
}
.input-container.s {
  margin-bottom: 1rem;
}
.input-container.l {
  margin-bottom: 3rem;
}
.input-container a {
  font-size: 1.4rem;
  color: #000;
}
.input-container a:hover {
  text-decoration: none;
}
.input-container.input--group {
  display: flex;
  align-items: center;
}
.input-container.input--group.s .input__item {
  padding-right: 6rem;
  height: 35px;
  font-size: 1.1rem;
}
.input-container.input--group.s .btn {
  position: absolute;
  right: 10px;
  top: 9px;
  font-weight: 700;
  font-size: 1.2rem;
}
.input-container.input--group .input__item {
  padding-right: 8rem;
}
.input-container.input--group .btn {
  position: absolute;
  right: 15px;
  top: 15px;
  font-weight: 700;
}
.input-container.input--group i {
  color: #A0A0A0;
}
.input-container .input__label {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
  display: inline-block;
}
.input-container .input__item {
  padding: 0 1.6rem;
  border-radius: 5px;
  width: 100%;
  color: #000;
  border: 1px solid #E6E6E6;
  height: 50px;
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
}
.input-container .input__item.s {
  height: 35px;
  font-size: 1.2rem;
}
.input-container .input__item.dark {
  border: 1px solid #4D4D4D;
}
.input-container .input__item::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #AAAAAA;
  opacity: 1;
  /* Firefox */
}
.input-container .input__item:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #AAAAAA;
}
.input-container .input__item::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #AAAAAA;
}
.input-container .input__item.textarea {
  padding: 1.6rem;
  min-height: 70px;
  line-height: 1.4rem;
}
.input-container .input__item:focus {
  outline: none;
}
.input-container .custom-select {
  box-shadow: none;
  border-radius: 5px;
  height: 50px;
  position: relative;
  width: 100%;
  font-size: 1.4rem;
  background: #fff;
  border: 1px solid #E6E6E6;
  z-index: 0;
}
.input-container .custom-select.error {
  border: 1px solid #EF4444;
}
.input-container .custom-select:before {
  content: "";
  position: absolute;
  right: 1.6rem;
  top: 2rem;
  background: url("/img/chevron-down.svg");
  height: 10px;
  width: 17px;
  z-index: -1;
}
.input-container .custom-select select {
  color: #000;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 100%;
  padding: 0 1.6rem;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  z-index: 0;
  border: none;
}
.input-container .custom-select select:focus {
  outline: none;
  border: none;
}

.custom-check {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 30px;
  font-size: 1.4rem;
}
.custom-check a {
  text-decoration: underline;
}
.custom-check.s .checkmark {
  height: 17px;
  width: 17px;
}
.custom-check.m .checkmark {
  height: 22px;
  width: 22px;
}
.custom-check.m .checkmark:after {
  left: 7px;
  top: 3px;
}
.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-check input:checked ~ .checkmark {
  background-color: #00A99D;
  border: 1px solid #00A99D;
}
.custom-check input:checked ~ .checkmark:after {
  display: block;
}
.custom-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fafafa;
  border-radius: 3px;
  border: 1px solid #ddd;
}
.custom-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-check .checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg);
}
.content-check-container {
  display: flex;
  flex-wrap: wrap;
}
.content-check-container label {
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 0.9rem;
  margin-bottom: 0.9rem;
  flex-basis: calc(33.3% - 0.6rem);
}
.content-check-container label:nth-of-type(3n), .content-check-container label:last-of-type {
  margin-right: 0;
}
.content-check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.content-check-container input:checked ~ .content-check {
  background-color: #F0FFF4;
  border: 1px solid #38A169;
  color: #22543D;
}
.content-check-container input:checked ~ .content-check:before {
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  background: #9AE6B4;
  height: 20px;
  width: 20px;
}
.content-check-container input:checked ~ .content-check:after {
  content: "";
  right: 17px;
  top: 13px;
  width: 4px;
  height: 9px;
  border: solid #2F855A;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg);
  position: absolute;
}
.content-check-container input:hover ~ .content-check {
  background-color: #F0FFF4;
  border: 1px solid #F0FFF4;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
}
.content-check-container .content-check {
  position: relative;
  height: 70px;
  background: #fff;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  padding: 0.7rem 1rem;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content-check-container .content-check .check__name {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  opacity: 0.5;
}
.content-check-container .content-check .check__price {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.8rem;
}
.content-check-container .content-check .check__price .price--discount {
  font-size: 70%;
  color: #A0A0A0;
  font-weight: 600;
}

.form__name {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 1.4rem;
  text-align: left;
}

.form__subname {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 1.6rem;
  text-align: left;
  color: #A0A0A0;
}

.form__container {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.form__container .form__column {
  flex-basis: 100%;
  margin-right: 0;
  position: relative;
}
.form__container .form__column .form__name {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  text-align: left;
}
.form__container .form__column.featured:before {
  content: "";
  position: absolute;
  top: -2rem;
  left: -2rem;
  width: calc(100% + 4rem);
  height: calc(100% + 3rem);
  border-radius: 5px;
  border: 1px solid #000;
  background: transparent;
  z-index: 0;
}
.form__container .form__column.mr {
  margin-right: 4rem;
}
.form__container .form__column .btn {
  margin-top: 3rem;
  display: block;
}

@media (max-width: 768px) {
  .custom-check.s {
    width: 100%;
  }

  .form__name {
    font-size: 1.4rem;
  }
}
.euro {
  font-family: Arial;
}

.strike {
  position: relative;
}
.strike:before {
  content: "";
  height: 2px;
  width: 110%;
  background: red;
  position: absolute;
  left: -5%;
  top: 45%;
  transform: rotate(-10deg);
  box-shadow: 0 1px 0 0 white;
}
.strike.strike--s:before {
  height: 1px;
  box-shadow: none;
}

strong {
  font-weight: 700;
}

.light {
  font-weight: 300;
}

.text--xxl {
  font-size: 6rem;
}

.text--m {
  font-size: 1.6rem;
}

.text--s {
  font-size: 1.2rem;
}

.text--xs {
  font-size: 1rem;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right !important;
}

.text--danger {
  color: #EF4444 !important;
}

.text--danger-dark {
  color: #7F1D1D !important;
}

.text--warning {
  color: #F7931E !important;
}

.text--success {
  color: #10B981;
}

.text--success-dark {
  color: #064E3B;
}

.text--light {
  color: #A0A0A0;
}

.text--featured {
  color: #00A99D;
}

.text--up {
  text-transform: uppercase;
}

.d--block {
  display: block;
}

.d--flex {
  display: flex;
}

.fd--c {
  flex-direction: column;
}

.fw--w {
  flex-wrap: wrap;
}

.jc--c {
  justify-content: center;
}

.jc--fe {
  justify-content: flex-end;
}

.jc--sb {
  justify-content: space-between;
}

.ai--fs {
  align-items: flex-start;
}

.ai--c {
  align-items: center;
}

.pt--0 {
  padding-top: 0 !important;
}

.pb--0 {
  padding-bottom: 0 !important;
}

.ml--0 {
  margin-left: 0 !important;
}

.ml--xs {
  margin-left: 1rem !important;
}

.ml--s {
  margin-left: 2rem !important;
}

.mt--0 {
  margin-top: 0 !important;
}

.mt--xs {
  margin-top: 1rem !important;
}

.mt--s {
  margin-top: 2rem !important;
}

.mt--m {
  margin-top: 4rem !important;
}

.mr--xxs {
  margin-right: 0.6rem !important;
}

.mr--xs {
  margin-right: 1rem !important;
}

.mr--s {
  margin-right: 2rem !important;
}

.mr--m {
  margin-right: 4rem !important;
}

.mb--0 {
  margin-bottom: 0 !important;
}

.mb--xxs {
  margin-bottom: 0.6rem !important;
}

.mb--xs {
  margin-bottom: 1rem !important;
}

.mb--s {
  margin-bottom: 2rem !important;
}

.mb--m {
  margin-bottom: 4rem !important;
}

.w--50 {
  width: 50%;
}

.w--60 {
  width: 60%;
}

.w--100 {
  width: 100%;
}

.text--link {
  text-decoration: none;
}
.text--link:hover {
  text-decoration: underline !important;
}

.img--bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.place-card {
  display: none;
}

@media (max-width: 768px) {
  .sm\:d--none {
    display: none;
  }

  .sm\:w--100 {
    width: 100%;
  }
}